import * as React from "react";
import "./analyze.scss";
import { connect } from "react-redux";
import { apiPrefix, baseTitle, TableCSVExporter } from "../../helper";
import { ToastContainer, toast } from "react-toastify";
import OutsideClick from 'detect-outside-click-react';
import { Helmet } from "react-helmet";
import axios from "axios";
import ReactLoading from "react-loading";
import Layout from "../Layout";
import { logoutUser } from "../login/actions/authActions";

interface Props {
  token: any;
  location: any;
  logoutUser: () => any;
}


const TITLE = `${baseTitle} Test analyze`;

class Analyze extends React.Component<
  Props,
  {
   
  }
> {
  constructor(props: Props) {
    super(props);
    this.state = {
     
    };
  }

  

  render() {
    return (
      <React.Fragment>
        <div className="full-height">
          <Layout {...this.props}>
            <Helmet>
              <title>{TITLE}</title>
            </Helmet>
            <div className="main-analyze">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="analyze_card">
                      <div className="analyze_header">
                          <h4>OVERALL TEST</h4>
                      </div>
                      <div className="analyze_body">
                        <ul>
                          <li>
                            <h5>Total</h5>
                            <p>2324324</p>
                          </li>
                          <li>
                            <h5>Today's</h5>
                            <p>2324324</p>
                          </li>
                          <li>
                            <h5>Weekly's</h5>
                            <p>2324324</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="analyze_card">
                      <div className="analyze_header">
                          <h4>STATUS</h4>
                        <ul className="nav nav-tabs tab_bar">
                          <li className="">
                            <a className="active" data-toggle="tab" href="#home" title="safe">
                              <p className="green">Healthy</p>
                            </a>
                          </li>
                          <li className="">
                            <a className="" data-toggle="tab" href="#menu1" title="safe">
                              <p className="orange">flu</p>
                            </a>
                          </li>
                          <li className="">
                            <a className="" data-toggle="tab" href="#menu2" title="safe">
                              <p className="maroon">Allergy</p>
                            </a>
                          </li>
                          <li className="">
                            <a className="" data-toggle="tab" href="#menu3" title="safe">
                              <p className="red">COVID-19</p>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-content analyze_body ">
                        <div id="home" className="tab-pane active">
                          <ul>
                            <li>
                              <h5>Male</h5>
                              <p>2324</p>
                            </li>
                            <li>
                              <h5>Female</h5>
                              <p>23224</p>
                            </li>
                            <li>
                              <h5>Other's</h5>
                              <p>2324</p>
                            </li>
                          </ul>
                        </div>
                        <div id="menu1" className="tab-pane ">
                          <ul>
                            <li>
                              <h5>Male</h5>
                              <p>24324</p>
                            </li>
                            <li>
                              <h5>Female</h5>
                              <p>23204324</p>
                            </li>
                            <li>
                              <h5>Other's</h5>
                              <p>23324</p>
                            </li>
                          </ul>
                        </div>
                        <div id="menu2" className="tab-pane">
                          <ul>
                            <li>
                              <h5>Male</h5>
                              <p>24324</p>
                            </li>
                            <li>
                              <h5>Female</h5>
                              <p>23244</p>
                            </li>
                            <li>
                              <h5>Other's</h5>
                              <p>2324324</p>
                            </li>
                          </ul>
                        </div>
                        <div id="menu3" className="tab-pane">
                          <ul>
                            <li>
                              <h5>Male</h5>
                              <p>243s24</p>
                            </li>
                            <li>
                              <h5>Female</h5>
                              <p>2324dd4</p>
                            </li>
                            <li>
                              <h5>Other's</h5>
                              <p>23243324</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="analyze_card">
                      <div className="analyze_header">
                        <h4>Top probably affected area</h4>
                      </div>
                      <div className="analyze_body">
                        <ul>
                          <li>
                            <h5>Total</h5>
                            <p>2324324</p>
                          </li>
                          <li>
                            <h5>Today's</h5>
                            <p>2324324</p>
                          </li>
                          <li>
                            <h5>Weekly's</h5>
                            <p>2324324</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="analyze_card">
                      <div className="analyze_header">
                        <h4>Top affected age range</h4>
                      </div>
                      <div className="analyze_body">
                        <ul>
                          <li>
                            <p>0-10</p>
                          </li>
                          <li>
                            <p>20-40</p>
                          </li>
                          <li>
                            <p>40-60+</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main_table">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="new_card card table_card">
                        <div className="d-flex p_right  d-flex justify-content-end">
                          <div className="table_button">
                            <img src="assets/share.png" />
                            <p className="add_segment ">
                              Export as CSV
                            </p>
                          </div>
                        </div>
                        <div className="card-body new_card_body">
                          <table className="table new_table" id="LeadsDataTable">
                            <thead className="people-table-head">
                            <tr className="table_head">
                              <th scope="col">Name</th>
                              <th scope="col">Gender</th>
                              <th scope="col">Age</th>
                              <th scope="col">Region</th>
                              <th scope="col">Fever</th>
                              <th scope="col">Temperature</th>
                              <th scope="col">Difficult Breath</th>
                              <th scope="col">Dry Cough</th>
                              <th scope="col">Headache</th>
                              <th scope="col">Visit Affected Countries</th>
                              <th scope="col">Status</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Layout>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  token: state.auth.currentUserToken
});

export default connect(mapStateToProps, { logoutUser })(Analyze);
